import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,7,8,9,3,5];

export const dictionary = {
		"/": [11],
		"/about": [30],
		"/dashboard": [~31,[6]],
		"/dashboard/account": [32,[6]],
		"/dashboard/account/change-password": [33,[6]],
		"/dashboard/account/contact-support": [34,[6]],
		"/dashboard/account/personal-info": [35,[6]],
		"/dashboard/brands": [~36,[6]],
		"/dashboard/brands/[brand]": [~37,[7]],
		"/dashboard/brands/[brand]/edit": [~38,[8]],
		"/dashboard/browse": [~39,[6]],
		"/dashboard/browse/[distributor]": [40,[9]],
		"/dashboard/browse/[distributor]/design": [41,[9]],
		"/dashboard/browse/[distributor]/design/[menuID]/legal": [~42,[9]],
		"/dashboard/browse/[distributor]/design/[menuID]/success": [~43,[9]],
		"/dashboard/design": [44,[6]],
		"/(auth)/forgot-password": [12,[2]],
		"/(auth)/join-waitlist": [13,[2]],
		"/(auth)/log-in": [~14,[2]],
		"/(auth)/log-out": [~15,[2]],
		"/marketplace-agreement": [45],
		"/merchant-dashboard": [~46,[10]],
		"/(auth)/merchant-log-in": [~16,[2]],
		"/notifications/[slug]": [47],
		"/orders/[orderNumber]": [~48],
		"/privacy-policy": [49],
		"/(auth)/registration": [~17,[2,3]],
		"/(auth)/registration/details": [22,[2,3]],
		"/(auth)/registration/password": [23,[2,3]],
		"/(auth)/registration/[domain]/legal": [18,[2,3,4]],
		"/(auth)/registration/[domain]/menu": [19,[2,3,4]],
		"/(auth)/registration/[domain]/preview": [20,[2,3,4]],
		"/(auth)/registration/[domain]/success": [21,[2,3,4]],
		"/(auth)/reset-password/[token]": [24,[2]],
		"/sentry-example": [50],
		"/(auth)/sign-up": [~25,[2]],
		"/terms-of-use": [51],
		"/(auth)/waitlist-confirmed": [26,[2]],
		"/[storeFront]": [27,[5]],
		"/[storeFront]/checkout": [28,[5]],
		"/[storeFront]/payment-success": [29,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';